<template>
  <kurcc-form-multi-choice-additional-buttons-dialog-template
    icon="mdi-alert-box-outline" title="Report an Issue" @cancel="cancel" @save="save">
    <template slot="body">
      <v-text-field v-model="localAnswer.title" label="Title" outlined placeholder="Enter title"/>
      <v-textarea v-model="localAnswer.description" label="Description (Optional)"
                  outlined placeholder="Enter description" rows="2"/>
      <kurcc-form-multi-choice-additional-buttons-photo-list ref="photos" :photos.sync="localAnswer.photos"/>
      <v-row class="text-h6 mt-3" dense>Priority</v-row>
      <v-row class="my-3" dense>
        <v-btn :outlined="!isSelected(priorities.low)" class="mb-1" color="success" width="140px"
               @click="setPriority(priorities.low)">Low
        </v-btn>
        <v-spacer/>
        <v-btn :outlined="!isSelected(priorities.medium)" class="mb-1" color="warning" width="140px"
               @click="setPriority(priorities.medium)">Medium
        </v-btn>
        <v-spacer/>
        <v-btn :outlined="!isSelected(priorities.high)" class="mb-1" color="error" width="140px"
               @click="setPriority(priorities.high)">High
        </v-btn>
      </v-row>
      <v-datetime-picker v-model="localAnswer.due_date" label="Due at (Optional)">
        <template slot="dateIcon">
          <v-icon>mdi-calendar</v-icon>
        </template>
        <template slot="timeIcon">
          <v-icon>mdi-clock</v-icon>
        </template>
      </v-datetime-picker>
      <v-textarea v-model="localAnswer.suggestedCorrectiveAction" class="mt-3"
                  label="Suggested corrective action (optional)"
                  outlined placeholder="Enter suggested corrective action" rows="3"/>
      <span>Click <strong>save</strong> to raise Issue when submitting your check results.</span>
    </template>
  </kurcc-form-multi-choice-additional-buttons-dialog-template>
</template>

<script>
import { updateQuestionOptionField } from '@/modules/inspection/helpers/helpers'

const priorities = Object.freeze({
  low: 1,
  medium: 2,
  high: 3
})

export default {
  name: 'KurccFormMultiChoiceAdditionalButtonsReportIssue',
  props: {
    answer: {
      required: true
    },
    indexes: {
      type: Object,
      required: true
    }
  },
  components: {
    KurccFormMultiChoiceAdditionalButtonsPhotoList:
      () => import('@/modules/inspection/components/custom-fields/KurccFormMultiChoiceAdditionalButtons/KurccFormMultiChoiceAdditionalButtonsPhotoList'),
    KurccFormMultiChoiceAdditionalButtonsDialogTemplate:
      () => import('@/modules/inspection/components/custom-fields/KurccFormMultiChoiceAdditionalButtons/KurccFormMultiChoiceAdditionalButtonsDialogTemplate')
  },
  data () {
    return {
      priorities,
      initialAnswer: undefined,
      localAnswer: undefined,
      updateQuestionOptionField
    }
  },
  computed: {
    isSelected () {
      return item => item === this.localAnswer.selectedPriority
    },
    emptyIssue () {
      return {
        title: null,
        description: null,
        due_date: null,
        photos: [],
        selectedPriority: 2,
        suggestedCorrectiveAction: null
      }
    }
  },
  watch: {
    answer: {
      handler: function (v) {
        this.initialAnswer = v
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    setPriority (priority) {
      this.localAnswer.selectedPriority = priority
    },
    cancel () {
      this.updateQuestionOptionField('reportIssueAnswer', this.initialAnswer, this.indexes)
      if (this.initialAnswer) {
        this.localAnswer = {
          ...this.initialAnswer
        }
      } else {
        this.localAnswer = this.cloneAnswerOrEmptyIssue()
      }
    },
    save () {
      this.$refs.photos.save()
      this.updateQuestionOptionField('reportIssueAnswer', {
        ...this.localAnswer
      }, this.indexes)
    },
    cloneAnswerOrEmptyIssue () {
      return this.$lodash.cloneDeep(this.answer ?? this.emptyIssue)
    }
  },
  created () {
    this.localAnswer = this.cloneAnswerOrEmptyIssue()
  }
}
</script>
